<template>
  <main class="w-full px-4 min-h-screen">
    <h4 class="md:mt-3 hidden md:block md:text-sm md:text-left text-xs text-headerText">Payroll / Run Payroll</h4>
    <div v-if="!processPayment">
      <div class="md:block hidden text-left my-6">
        <easiBackButton></easiBackButton>
      </div>

      <div class="flex w-64 flex-col mt-5 md:mt-0 items-start">
        <!-- <input
          v-model="selectedDate"
          @change="updateRecord"
          type="month"
          name=""
          id=""
          :max="maxDate"
          class="myinput"
        /> -->

        <input v-model="selectedDate" @change="updateRecord" type="month" name="" id="" class="myinput" />
      </div>

      <div v-if="!isSearch && allEmployees && !allEmployees.length" class="mt-16 mb-4">
        <easiEmptyState text=" You have not added any employee yet."></easiEmptyState>
      </div>

      <div v-else class="w-full mx-auto">
        <div
          class="flex md:flex-row flex-col md:justify-between justify-center mx-auto w-full md:gap-y-0 gap-y-8 md:items-center"
        >
          <div
            v-if="payrollForMonth && payrollForMonth.salaryRecords && payrollForMonth.salaryRecords.length"
            class="flex flex-wrap md:flex-nowrap md:items-center mt-4 w-full md:w-full justify-start mx-auto grow-0 space-x-4"
          >
            <div class="w-9/12 md:w-10/12">
              <easiSearch placeholder="Search employee" useNewclass v-model="searchText" @search="searchFNPayroll" />
            </div>

            <PayrollFilter ref="payrollHistoryDetailRef" @update="filterFNPayroll" />

            <div class="mt-5 md:mt-0 w-full md:w-auto">
              <easiButton @click="selectedEmployees.length ? toRunPayroll() : toast.error('No employee selected')"
                >Run Payroll</easiButton
              >
            </div>
          </div>
          <div
            v-else
            class="flex flex-wrap md:flex-nowrap md:items-center mt-4 w-full md:w-full justify-start mx-auto grow-0 space-x-4"
          >
            <!-- /////// Search Component ///// -->
            <div class="w-9/12 md:w-10/12">
              <easiSearch placeholder="Search employee by name" v-model="searchText" useNewclass @search="searchFN" />
            </div>

            <!-- /////// Filter Component Start /////// -->
            <div class="">
              <easiFilter @update="filterFN" />
            </div>
            <!-- /////// Filter Component Ends /////// -->
            <div class="mt-5 md:mt-0 w-full md:w-auto">
              <easiButton @click="selectedEmployees.length ? toRunPayroll() : toast.error('No employee selected')"
                >Run Payroll</easiButton
              >
            </div>
          </div>
        </div>
        <easiActiveFilter @close="removeFilter" :filters="activeFilters" />

        <div class="hidden md:hidden justify-start text-primary mt-5 space-x-5">
          <div
            class="flex space-x-2 cursor-pointer rounded-full border p-2 text-xs font-medium border-primary items-center"
          >
            <span>Edit </span>
            <img src="@/assets/icons/newEditIcon.svg" alt="" />
          </div>
          <div
            class="flex space-x-2 cursor-pointer rounded-full border p-2 text-xs font-medium border-primary items-center"
          >
            <span>Delete </span>
            <img src="@/assets/icons/newDelete.svg" alt="" />
          </div>
          <div
            class="flex space-x-2 cursor-pointer rounded-full border p-2 text-xs font-medium border-primary items-center"
          >
            <span>Archive </span>
            <img src="@/assets/icons/newArchive.svg" alt="" />
          </div>
        </div>

        <div
          class="flex text-center w-80 mt-6 border border-outlineGray rounded-xl tracking-wide justify-start md:mb-- mb-0 gap-x-2 p-2 text-sm font-medium cursor-pointer bg-white"
        >
          <span
            @click="toggleAdd"
            class="w-full text-sm rounded-lg md:px-5 md:py-2 px-3 py-2"
            :class="unPaid ? 'bg-primary shadow-2xl text-white' : 'bg-newGrey'"
            >Unpaid ({{ lengthByStatus && lengthByStatus.unPaid }})</span
          >
          <span
            @click="toggleAdd"
            class="w-full text-sm rounded-lg md:px-5 md:py-2 px-3 py-2"
            :class="!unPaid ? 'bg-primary shadow-2xl text-white' : 'bg-newGrey'"
            >Paid ({{ lengthByStatus && lengthByStatus.paid }})</span
          >
        </div>
      </div>

      <div v-if="allEmployees && allEmployees.length" class="mt-4">
        <div
          v-if="
            (payrollForMonth && payrollForMonth.salaryRecords && payrollForMonth.salaryRecords.length) ||
            (payrollForMonth &&
              payrollForMonth.unsentEmployees &&
              payrollForMonth.unsentEmployees.length &&
              historyPayload.month < currentMonth)
          "
        >
          <salary-table
            v-if="unPaid"
            @selected="updateSelectedEmployees($event, true)"
            :is-search="isSearch"
            :searchText="searchText"
            :paymentStatus="paymentStatusFilterOption"
            :showPaid="false"
          />
          <salary-table
            v-else
            @selected="updateSelectedEmployees($event, true)"
            :is-search="isSearch"
            :searchText="searchText"
            :paymentStatus="paymentStatusFilterOption"
            :showPaid="true"
          />
        </div>
        <!-- <easiPayrollTable
          :routable="true"
          :data="transformedData"
          :namesForAllowances="allowanceNames"
          :amountValues="amountValues"
          :frozenFields="['firstName', 'lastName']"
          :highlight="['firstName', 'lastName']"
          :header="headers"
          :useSelect="['department', 'employmentType', 'branch']"
          :hideAllowances="false"
          @selected="updateSelectedEmployees($event, false)"
        /> -->
        <easiPayrollTable
          v-else-if="
            payrollForMonth &&
            payrollForMonth.salaryRecords &&
            !payrollForMonth.salaryRecords.length &&
            historyPayload.month >= currentMonth &&
            historyPayload.year == currentYear &&
            unPaid == true
          "
          :routable="true"
          :data="transformedData"
          :namesForAllowances="allowanceNames"
          :amountValues="amountValues"
          :frozenFields="['firstName', 'lastName']"
          :highlight="['firstName', 'lastName']"
          :header="headers"
          :useSelect="['department', 'employmentType', 'branch']"
          :hideAllowances="false"
          @selected="updateSelectedEmployees($event, false)"
        />

        <div
          v-else
          class="container mx-auto items-center justify-center w-full sm:w-11/12 md:w-10/12 my-6 grid grid-cols-2 gap-2 mt-12"
        >
          <div class="flex flex-col text-left col-span-2 md:col-span-1">
            <h1 class="text-secondary text-5xl md:text-7xl font-semibold mb-4">Oops!</h1>
            <div class="text-sm md:text-lg">Sorry we couldn’t find a result for your request.</div>
            <p class="text-xs md:text-sm mt-2">Please check your search and filter, then try again.</p>
          </div>
          <div class="col-span-2 md:col-span-1">
            <img src="@/assets/icons/search_null.svg" alt="" />
          </div>
        </div>
      </div>

      <div
        v-if="isSearch && !allEmployees.length"
        class="container mx-auto items-center justify-center w-full sm:w-11/12 md:w-10/12 my-6 grid grid-cols-2 gap-2 mt-12"
      >
        <div class="flex flex-col text-left col-span-2 md:col-span-1">
          <h1 class="text-secondary text-5xl md:text-7xl font-semibold mb-4">Oops!</h1>
          <div class="text-sm md:text-lg">Sorry we could not find a result for your search</div>
          <p class="text-xs md:text-sm mt-2">
            Check your spelling or try a different name. Make sure you search by
            <span class="text-secondary font-medium">Name</span>,
            <span class="text-secondary font-medium">Employee ID</span> or
            <span class="text-secondary font-medium">Department</span>
          </p>
        </div>
        <div class="col-span-2 md:col-span-1">
          <img src="@/assets/icons/search_null.svg" alt="" />
        </div>
      </div>

      <easiModal v-if="prorate === true" @close="prorate = false">
        <template v-slot:header>
          <h1 class="capitalize font-medium text-headerText">Prorate Salary</h1>
        </template>

        <form v-show="!prorated" @submit.prevent="prorateSalary" class="my-5 flex flex-col w-full">
          <div class="flex flex-col items-start px-8">
            <label for="startDate" class="text-dark-800 text-left text-xs mt-2 font-medium">Select Start Date </label>
            <input
              placeholder="Select Start Date"
              required
              v-model="dates.startDate"
              type="date"
              name="startDate"
              class="mt-1 mb-5 px-8 rounded-2xl w-full border border-dark-200"
            />
          </div>
          <hr class="mt-2 mb-4 border-0.5 border-dividerColor text-dividerColor" />
          <div class="flex flex-col items-start px-8">
            <label for="startDate" class="text-dark-800 text-left text-xs mt-2 font-medium">Select End Date </label>
            <input
              placeholder="Select Start Date"
              required
              :min="dates.startDate"
              v-model="dates.endDate"
              type="date"
              name="startDate"
              class="mt-1 mb-5 px-8 rounded-2xl w-full border border-dark-200"
            />
          </div>

          <div class="flex flex-col w-full items-start px-8">
            <label for="weekendWork" class="text-dark-800 text-left text-xs mt-2 font-medium">Weekend Work </label>
            <easiSelectInput2
              required
              class="mt-1 mb-2 w-full text-left"
              @update="prorateArg.weekendDay = $event"
              :value="prorateArg.weekendDay"
              :options="prorateOption"
            />
          </div>

          <div class="flex flex-col items-start px-8">
            <label for="holidays" class="text-dark-800 text-left text-xs mt-2 font-medium">Number of Holidays </label>
            <easiSelectInput2
              required
              class="mt-1 mb-2 w-full text-left"
              @update="prorateArg.holidays = $event"
              :value="prorateArg.holidays"
              :options="holidaysArray"
              :grid="true"
            />
          </div>

          <div class="px-8 mt-4 w-64 justify-center mx-auto">
            <easiButton :loading="prorateLoading">Submit</easiButton>
          </div>
        </form>

        <div v-show="prorated" class="flex flex-col gap-y-1">
          <span class="text-dark-800 text-sm font-medium">Duration:</span>
          <span class="text-secondary font-medium">{{
            `${formatDateString(dates.startDate)} - ${formatDateString(dates.endDate)}`
          }}</span>

          <span class="text-dark-800 text-sm font-medium">Amount:</span>
          <span class="text-primary text-2xl font-medium">{{ proratedSalary }}</span>

          <easiButton
            @click="
              () => {
                prorate = false;
              }
            "
            class="my-6"
            >Submit</easiButton
          >
        </div>
      </easiModal>

      <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
        <template v-slot:message>
          <span>Salary prorated successfully</span>
        </template>
      </easiSuccess>
    </div>
    <div v-else class="w-full flex flex-col">
      <button @click="processPayment = false" class="self-start mt-3 text-dark-800 font-medium focus:outline-none">
        <i class="fas fa-angle-left mr-3 cursor-pointer"></i> Back
      </button>
      <RunPayroll @completed="updateAfterPayment" :employees="selectedEmployees" :allowances="allowanceNames" />
    </div>
    <easiRoundLoader v-if="loading" />
  </main>
</template>

<script setup>
import { ref, reactive, onMounted, watch, computed, onBeforeUnmount } from "vue";
import { storeToRefs } from "pinia";
import { useDataStore } from "@/stores/data.js";
import { helperFunctions } from "@/composable/helperFunctions";
import { onBeforeRouteLeave, useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toastification";

import SalaryFilter from "@/components/Filters/SalaryFilter.vue";
import PayrollFilter from "@/components/Filters/PayrollFilter.vue";

import RunPayroll from "@/components/Payroll/Edit/RunPayroll.vue";
import SalaryTable from "@/components/Payroll/Edit/SalaryTable.vue";

const store = useDataStore();
const { query, mutate } = store;
const { getAllEmployees, checkLoading, listAllowance } = storeToRefs(store);
const { calculatePercentage, formatAmount, formatDateString } = helperFunctions;
const router = useRouter();
const route = useRoute();

const toast = useToast();
const paymentStatusFilterOption = ref("all");

const loading = ref(false);
const searchText = ref("");
const processPayment = ref(false);
const unPaid = ref(true);
let isSearch = ref(false);
const viewEmployeeFilterRef = ref(null);
const payrollHistoryDetailRef = ref(null);

const activeFilters = ref([]);
const filterOptions = ref({
  sort: { firstName: "ASC" },
  filter: [],
});

const payrollForMonth = computed(() => {
  return store.getPayrollHistoryForMonth ? store.getPayrollHistoryForMonth : { salaryRecords: [] };
});
const allEmployees = computed(() => {
  if (getAllEmployees.value && getAllEmployees.value.data) {
    if (payrollForMonth.value && payrollForMonth.value.salaryRecords && payrollForMonth.value.salaryRecords.length) {
      const idsWithHistory = payrollForMonth.value.salaryRecords.map((emp) => emp.employeeId);
      const employees = getAllEmployees.value.data.filter((emp) => !idsWithHistory.includes(emp));
      return employees;
    } else {
      return getAllEmployees.value.data;
    }
  } else {
    return [];
  }
});
const lengthByStatus = computed(() => {
  if (payrollForMonth.value && payrollForMonth.value.salaryRecords && payrollForMonth.value.salaryRecords.length) {
    const paid = payrollForMonth.value.salaryRecords.filter((re) => re.status.toLowerCase() === "paid");
    return {
      paid: paid.length,
      unPaid: payrollForMonth.value.unsentEmployees.length,
    };
  } else if (
    payrollForMonth.value &&
    payrollForMonth.value.salaryRecords &&
    !payrollForMonth.value.salaryRecords.length
  ) {
    return {
      paid: 0,
      unPaid: allEmployees.value.length,
    };
  }
});

const failedPayrollEmployees = computed(() =>
  store.getEmployeeArrayForPayroll ? store.getEmployeeArrayForPayroll : []
);
let selectedEmployees = ref([]);

onMounted(() => {
  if (
    route.params.id &&
    route.params.id.length &&
    failedPayrollEmployees.value &&
    failedPayrollEmployees.value.length
  ) {
    selectedEmployees.value = failedPayrollEmployees.value.map((employeeId) => {
      return { employeeId };
    });
    processPayment.value = true;
  }
});

const amountValues = [
  "paye",
  "pension",
  "hmo",
  "lifeInsurance",
  "lifeAssurance",
  "wht",
  "nsitf",
  "itf",
  "gratuity",
  "nhf",
  "businessExpense",
  "employeePension",
  "employerPension",
  "grossPay",
  "netPay",
];
const currentPage = ref(0);
const pageSize = ref(15);
let visibleData = ref([]);

const payload = ref({
  paging: {
    limit: 1000,
    skip: 0,
    search: "",
    sort: {
      firstName: "ASC",
    },
  },
  filter: [],
});

watch(searchText, async (val) => {
  if (val.trim() === "") {
    payload.value.paging.search = "";
    await queryEmployees();
    isSearch.value = false;
  }
});
const allAllowanceData = computed(() =>
  store.listAllowance && store.listAllowance.data ? store.listAllowance : { data: [] }
);

function getUniqueListBy(arr, key) {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
}

async function queryCompanyAdmin() {
  try {
    await query({
      endpoint: "FetchCompanyAdmin",
      service: "SETTINGS",
      storeKey: "companyAdmin",
    });
  } catch (e) {
    console.log(e);
  }
}
queryCompanyAdmin();
const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth();
const mth = Number(currentMonth) + 1;
const ff = String(mth).padStart(2, "0");
const selectedDate = ref("");
const maxDate = `${currentYear}-${ff}`;
selectedDate.value = `${currentYear}-${ff}`;

const historyPayload = ref({
  month: currentMonth,
  year: currentYear,
  timeline: "MONTHLY",
  // biweekly: null,
  sort: {},
  paging: {
    limit: 1000,
    skip: 0,
    search: "",
  },
  filter: [],
});

const queryViewPayrollForMonthV3 = async () => {
  try {
    loading.value = true;
    await query({
      endpoint: "ViewPayrollForMonthV3",
      payload: {
        input: historyPayload.value,
      },
      service: "PAYROLL",
      storeKey: "payrollForMonth",
    });
    // console.log(payrollForMonth.value, "PFM");
    // queryLoading.value = false;
  } catch (err) {
    // queryLoading.value = false;
    console.log(err);
  } finally {
    loading.value = false;
  }
};

onMounted(async () => {
  await queryViewPayrollForMonthV3();
});

const updateRecord = async () => {
  const rec = selectedDate.value.split("-");
  historyPayload.value.year = Number(rec[0]);
  historyPayload.value.month = Number(String(rec[1] - 1).padStart(2, "0"));
  await queryViewPayrollForMonthV3();
};

function transform(arr) {
  const newArr = arr.map((obj) => {
    let data = {
      // id: obj.job.userName,
      employeeId: obj._id,
      firstName: obj.profile.firstName,
      lastName: obj.profile.lastName,
      picture: obj.profile.pfp,

      grossPay: obj.staffLevel ? Number(obj.staffLevel.grossAmount) : 0,
      netPay:
        obj.staffLevel && obj.prorate && obj.prorate.staffLevel && Number(obj.prorate.staffLevel.netAmount) > 0
          ? Number(obj.prorate.staffLevel.netAmount)
          : obj.staffLevel
          ? Number(obj.staffLevel.netAmount)
          : 0,
      accountName: obj.payment && obj.payment.accountName ? obj.payment.accountName : "N/A",
      accountNumber: obj.payment && obj.payment.accountNumber ? obj.payment.accountNumber : "N/A",
      bankName: obj.payment && obj.payment.bankName ? obj.payment.bankName : "N/A",
      //   department: obj.job.departmentId,
      //   employmentType: obj.job.employmentType,
      //   branch: obj.job.branchId,
      usePercentage: obj.staffLevel && obj.staffLevel.usePercentageForAllowance,
      staffLevelId: obj.staffLevel && obj.staffLevel._id,
      // deductions: obj.staffLevel && obj.staffLevel.benefits,
      allowanceDetails: obj.staffLevel && obj.staffLevel.allowanceDetails,
      prorate: obj.prorate && obj.prorate.proratedSalary ? obj.prorate : {},

      // itf: obj.staffLevel.itf ? obj.staffLevel.itf.amount : 0,
      nhf: obj.staffLevel && obj.staffLevel.nhf ? obj.staffLevel.nhf.amount : 0,
      hmo: obj.staffLevel && obj.staffLevel.hmo ? obj.staffLevel.hmo.amount : 0,
      employeePension:
        obj.staffLevel && obj.staffLevel.pension ? obj.staffLevel.pension.employeePensionContribution : 0,
      employerPension:
        obj.staffLevel && obj.staffLevel.pension ? obj.staffLevel.pension.employerPensionContribution : 0,
      paye: obj.staffLevel && obj.staffLevel.paye ? obj.staffLevel.paye.amount : 0,
      lifeAssurance: obj.staffLevel && obj.staffLevel.lifeAssurance,
      //   ? obj.staffLevel.lifeAssurance.amount
      //   : 0,
      // lifeInsurance: obj.staffLevel.lifeInsurance
      //   ? obj.staffLevel.lifeInsurance.amount
      //   : 0,
      // wht: obj.staffLevel.wht ? obj.staffLevel.wht.amount : 0,
      // nsitf: obj.staffLevel.nsitf ? obj.staffLevel.nsitf.amount : 0,
      // gratuity: obj.staffLevel.gratuity ? obj.staffLevel.gratuity.amount : 0,
      // businessExpense: obj.staffLevel.businessExpense
      //   ? obj.staffLevel.businessExpense.amount
      //   : 0,
    };

    obj.bonusOrDeductions.forEach((item) => {
      data[`${item.name.toLowerCase()}${item.type}`] = item.percentage
        ? formatAmount(calculatePercentage(data.grossPay, item.percentage))
        : formatAmount(item.amount);
    });
    return data;
  });

  return newArr;
}
const allowanceNames = ref([]);
function getAllowanceNameById(id) {
  for (let data of allAllowanceData.value.data) {
    let allName;
    // allowanceNames.value.push(data.name);
    if (data._id === id) {
      allName = data.name;
      return allName;
    }
  }
}

function getAllowanceNames() {
  const names = allAllowanceData.value && allAllowanceData.value.data && allAllowanceData.value.data.map((n) => n.name);
  return names;
}
allowanceNames.value = getAllowanceNames();

function createCustomAllowanceDetails() {
  const employees = allEmployees.value;
  for (let emp of employees) {
    for (let aw of allAllowanceData.value.data) {
      if (emp.staffLevel.allowanceDetails.allowanceId !== aw._id) {
        emp.staffLevel.allowanceDetails.push({
          _id: null,
          allowanceId: aw._id,
          amount: 0,
          percentage: 0,
        });
      }
    }
  }
}
// createCustomAllowanceDetails();

function extractAllowance(arr) {
  const allowance = arr.map((obj) => {
    const details =
      obj.staffLevel &&
      obj.staffLevel.allowanceDetails.map((ad) => {
        return {
          amount: ad.amount,
          percentage: ad.percentage,
          usePercentage: obj.staffLevel.usePercentageForAllowance,
          name: getAllowanceNameById(ad.allowanceId),
        };
      });

    return details;
  });

  return allowance;
}
const allowance = ref([]);
allowance.value = extractAllowance(allEmployees.value);

function finalVal() {
  const fna = allowance.value.map((item, index) => {
    const info = item && item.reduce((acc, cur) => ({ ...acc, [cur.name]: cur.percentage || cur.amount }), {});
    return info;
  });
  return fna;
}
const datum = ref([]);
datum.value = finalVal();
const transformedData = ref([]);
transformedData.value = transform(allEmployees.value);

const headers = computed(() => {
  if (payrollData.value.length > 0) {
    //get all object keys and remove duplicates
    let allKeys = [];
    payrollData.value.forEach((item) => {
      for (const i in item) {
        allKeys.push(i);
      }
    });
    // console.log(allKeys, "full keys");
    let headArr = [];
    const uniqueKeys = [[...new Set(allKeys)]];
    // const head = Object.keys(payrollData.value[0]);
    for (const i in uniqueKeys) {
      headArr.push({
        field: i,
        header: i,
      });
    }
    return headArr;
  }
});

const searchFN = async (suggested) => {
  // console.log(suggested, searchText.value);
  searchText.value = suggested ? suggested : searchText.value;
  isSearch.value = true;
  payload.value.paging.search = searchText.value;
  await queryEmployees();
};

// const filterFN = async (arg) => {
//   console.log(arg, "fil");
//   isSearch.value = true;
//   filterOptions.value = arg;

//   payload.value.paging.sort = filterOptions.value.sort;
//   payload.value.filter = filterOptions.value.filter;

//   await queryEmployees();
// };

const filterFN = async ({ filter, active }) => {
  isSearch.value = true;
  filterOptions.value = filter;

  payload.value.paging.sort = filterOptions.value.sort;
  payload.value.filter = filterOptions.value.filter;

  await queryEmployees();

  activeFilters.value = active;
};

const searchFNPayroll = async (suggested) => {
  // console.log(searchText.value);
  isSearch.value = true;
  payload.value.paging.search = searchText.value;
  await queryViewPayrollForMonthV3();
};

const filterFNPayroll = async ({ filter, active }) => {
  // console.log(filter, active);
  isSearch.value = active.length ? true : false;
  filterOptions.value = filter;
  historyPayload.value.sort = filterOptions.value.sort;

  if (filterOptions.value.filter.length) {
    paymentStatusFilterOption.value = filterOptions.value.filter[0].value;
    // console.log("Filter By Payment Status", paymentStatusFilterOption.value);
  } else {
    paymentStatusFilterOption.value = "all";
  }

  // console.log(payload.value);
  await queryViewPayrollForMonthV3();

  activeFilters.value = active;
};

const removeFilter = (arg) => {
  // console.log(payrollHistoryDetailRef.value);
  if (payrollHistoryDetailRef.value && payrollHistoryDetailRef.value.removeFilter) {
    payrollHistoryDetailRef.value.removeFilter(arg);
  }
};

const queryEmployees = async () => {
  loading.value = true;
  try {
    await query({
      endpoint: "ListEmployees",
      payload: payload.value,
      service: "EMP",
      storeKey: "listEmployees",
    });

    loading.value = false;
  } catch (err) {
    loading.value = false;
    console.log(err);
  }
};

function updateSelectedEmployees(arg, plain) {
  // selectedEmployees.value = arg.map((el)=>el);
  selectedEmployees.value = [];
  if (plain) {
    for (let employeeId of arg) {
      selectedEmployees.value.push({ employeeId });
    }
  } else {
    for (let emp of arg) {
      selectedEmployees.value.push(emp);
    }
  }

  selectedEmployees.value = getUniqueListBy(selectedEmployees.value, "employeeId");
}

function empty() {
  getAllEmployees.value.data = [];
}
// Get rid of the proxy when console.log
function log(arg) {
  console.log(JSON.parse(JSON.stringify(arg)));
}
function cross() {
  const employee = transformedData.value;
  let newTransformed = [];
  const fresh = employee.map((emp, index) => {
    const fin = datum.value.map((du, i) => {
      if (index === i) {
        const merged = Object.assign({}, emp, du);
        newTransformed.push(merged);
        return merged;
      }
    });
  });
  for (let all of newTransformed) {
    for (let k of allowanceNames.value) {
      if (!all[k]) {
        all[k] = 0;
      }
    }
  }
  return newTransformed;
}
const payrollData = ref([]);
payrollData.value = cross();
function goToBulkEdit() {
  store.$patch({
    payrollData: payrollData.value,
    allowanceNames: allowanceNames.value,
  });
  router.push({ name: "SalaryBulkEdit" });
}
const updatePage = (pageNumber) => {
  currentPage.value = pageNumber;
  updateVisibleData();
};
const updateVisibleData = () => {
  let datas = transformedData.value;
  visibleData.value = datas.slice(
    currentPage.value * pageSize.value,
    currentPage.value * pageSize.value + pageSize.value
  );

  // if we have 0 visible todos, go back a page
  if (visibleData.value.length == 0 && currentPage.value > 0) {
    updatePage(currentPage.value - 1);
  }
};
watch(allEmployees, (val) => {
  transformedData.value = transform(val);
  // console.log("UPDATED", transformedData.value);

  allowance.value = extractAllowance(val);
  datum.value = finalVal();
  payrollData.value = cross();

  // updateVisibleData();
});

async function queryAllowance() {
  try {
    await query({
      endpoint: "ListAllowance",
      payload: {
        paging: {
          limit: 1000,
          skip: 0,
          search: null,
        },
      },
      service: "PAYROLL",
      storeKey: "allowance",
    });
  } catch (e) {
    console.log(e);
  }
}

onMounted(async () => {
  await queryEmployees();
  await queryAllowance();

  // updateVisibleData();
});

//Prorate Logic
const updateSuccess = ref(false);
const dates = ref({
  startDate: "",
  endDate: "",
});
const prorateArg = ref({
  employeeId: "",
  month: 0,
  year: 0,
  days: 0,
  weekendDay: "BOTH",
  holidays: 0,
});

const today = ref("");
const holidaysArray = ref([]);
const prorate = ref(false);
const prorateLoading = ref(false);
const prorated = ref(false);
const proratedSalary = ref(0);
const prorateOption = ref([
  {
    label: "NONE",
    value: "NONE",
  },
  {
    label: "Saturday",
    value: "SATURDAY",
  },
  {
    label: "Sunday",
    value: "SUNDAY",
  },
  {
    label: "Saturday and Sunday",
    value: "BOTH",
  },
]);

function openProrateModal() {
  selectedEmployees.value.length != 1
    ? toast.error("You can only prorate one (1) employee's salary at a time.")
    : (prorate.value = true);
}
function getNumOfDays(start, end) {
  const startDate = new Date(start);
  const endDate = new Date(end);

  // Calculate the time difference in milliseconds
  const timeDiff = endDate - startDate;

  // Convert milliseconds to days
  const daysDiff = timeDiff / (1000 * 60 * 60 * 24);
  return daysDiff;
}

function calculateProration() {
  prorateArg.value.employeeId = selectedEmployees.value[0].employeeId;
  let { startDate, endDate } = dates.value;
  const dateArr = startDate.split("-");
  const start = Number(startDate.slice(8));
  const end = Number(endDate.slice(8));
  const month = Number(startDate.slice(5, 7)) - 1;
  const year = Number(startDate.slice(0, 4));
  // const days = Math.abs(start - end);
  const days = getNumOfDays(startDate, endDate);

  prorateArg.value.month = month;
  prorateArg.value.year = year;
  prorateArg.value.days = days;
  const input = {
    month,
    days,
    year,
    holidays: prorateArg.value.holidays,
    holidays: prorateArg.value.holidays,
    weekendDay: prorateArg.value.weekendDay,
  };
}

async function prorateSalary() {
  calculateProration();

  try {
    prorateLoading.value = true;
    let res = await mutate({
      endpoint: "Prorate",
      data: {
        input: prorateArg.value,
      },
      service: "PAYROLL",
    });
    if (res && res._id) {
      proratedSalary.value = formatAmount(res.proratedSalary);
      prorated.value = true;
      prorateLoading.value = false;
      await queryEmployees();
      updateSuccess.value = true;

      setTimeout(() => {
        updateSuccess.value = false;
        window.location.reload();
      }, 1000);
    }
  } catch (e) {
    prorateLoading.value = false;
    console.log(e);
  }
}

async function updateAfterPayment() {
  processPayment.value = false;
  await queryEmployees();
}

function populateHolidayArray() {
  for (let i = 1; i <= 31; i++) {
    holidaysArray.value.push({
      label: i < 10 ? `0${i}` : `${i}`,
      value: i,
    });
  }
}
populateHolidayArray();

function toggleAdd() {
  searchText.value = "";
  payload.value.paging.search = "";
  historyPayload.value.paging.search = "";

  isSearch.value = false;
  payload.value.filter = [];
  historyPayload.value.filter = [];
  activeFilters.value = [];
  payload.value.sort = {};
  historyPayload.value.sort = {};
  unPaid.value = !unPaid.value;
}

function toRunPayroll() {
  // if (!payrollForMonth.value.salaryRecords.length) {
  //   const month = currentMonth;
  //   const year = currentYear;
  //   const date = `${month}-${year}`;

  //   store.$patch({
  //     employeeArrayForPayroll: selectedEmployees.value,
  //     payrollDate: date,
  //   });
  //   processPayment.value = true;
  //   return;
  // }
  const month = historyPayload.value.month;
  const year = historyPayload.value.year;
  const date = `${month}-${year}`;

  store.$patch({
    employeeArrayForPayroll: selectedEmployees.value,
    payrollDate: date,
  });
  processPayment.value = true;
  // router.push({ name: "PaySalary", params: { id: date } });
}

onBeforeRouteLeave((to, from, next) => {
  if (confirm("You are about to leave this page. Are you sure you want to continue?")) {
    // User confirmed, continue with navigation
    next();
  } else {
    // User canceled, stay on the current page
    next(false);
  }
});
const handleBeforeUnload = (event) => {
  const confirmationMessage = "You have unsaved changes. Are you sure you want to leave?";
  event.returnValue = confirmationMessage;
  return confirmationMessage;
};
onBeforeUnmount(() => {
  window.removeEventListener("beforeunload", handleBeforeUnload);
});

window.addEventListener("beforeunload", handleBeforeUnload);
</script>

<style>
.myinput {
  background-color: white;
  border: 2px solid #e7e8e7 !important;
  border-radius: 18px !important;
}
.p-monthpicker {
  background-color: white;
  border: 1px solid #e7e8e7 !important;
  border-radius: 18px !important;
}
:deep(.p-calendar .p-monthpicker) {
  background-color: white;
  border-color: #e7e8e7;
  color: #ffffff;
}
</style>
