<template>
  <main class="w-full min-h-screen px-2 sm:px-4 py-4">
    <div
      class="flex flex-row-reverse items-center justify-between sm:flex-col sm:items-start mb-2 sm:mb-0"
    >
      <h4
        class="md:mt-3 md:text-sm md:text-left text-xs font-medium text-dark-600 sm:text-headerText capitalize sm:uppercase"
      >
        Payroll / Bonus and Deduction / {{ route.query.type }} History
      </h4>
      <div class="text-left sm:mt-4 mb-2 sm:mb-0">
        <easiBackButton></easiBackButton>
      </div>
    </div>

    <div
      class="bg-white border rounded-2xl p-8 border-outlineGray my-6 md:my-6 w-full"
    >
      <!-- top card ///// -->
      <div v-if="loading" class="w-full">
        <easiLoader />
      </div>
      <section class="flex justify-between rounded-xl bg-newAsh text-black">
        <div
          class="flex flex-col w-full py-6 items-center justify-center border-r-2 border-outlineGray"
        >
          <p class="text-sm font-medium">Total number of staff paid</p>
          <p class="font-bold text-2xl">
            {{
              bonusOrDedDataHistory.totalAmountnEmployeesPaid
                ? bonusOrDedDataHistory.totalAmountnEmployeesPaid
                    .totalUniqueEmployees
                : ""
            }}
          </p>
        </div>
        <div
          class="flex flex-col w-full items-center justify-center border-r border-outlineGray"
        >
          <p class="text-sm font-medium">TOTAL AMOUNT PAID</p>
          <p class="font-bold text-2xl">
            {{
              bonusOrDedDataHistory.totalAmountnEmployeesPaid
                ? formatAmount(
                    bonusOrDedDataHistory.totalAmountnEmployeesPaid.totalAmount
                  )
                : ""
            }}
          </p>
        </div>
      </section>

      <section class="mt-8 flex gap-4">
        <div
          class="border p-2 bg-landingGrey flex gap-2 border-outlineGray rounded-2xl"
        >
          <BonusDropDown
            v-model:year="selectedMonth"
            @update:year="handleMonthSelect"
            :options="months"
            label="Choose Month"
          />
          <BonusDropDown
            v-model:year="apiFilter.year"
            :options="yearsArr"
            label="Choose Year"
          />
        </div>
        <div
          class="border p-2 bg-landingGrey flex gap-2 border-outlineGray rounded-2xl"
        >
          <BonusDropDown
            v-model:year="apiFilter.bndName"
            :options="options"
            :label="'Choose ' + pageType"
          />
        </div>
        <div class="w-6/12 h-full">
          <ApiDashBoardSearch
            @update:modelValue="searchData"
            placeholder="Search employee name, bonus..."
          />
        </div>

        <HistoryActions @act="handleAction" />
      </section>
      <section class="mt-8">
        <p class="text-grey font-bold text-xl mb-8">July 2024</p>
        <HistoryTable
          :currentPage="currentPage - 1"
          :pageSize="pageSize"
          :totalRecords="totalRecords"
          @update:pageSize="handlePagesize"
          @page:update="updatePage"
        />
      </section>
    </div>
  </main>
</template>

<script setup>
import BonusDropDown from "@/components/global/BonusDropDown.vue";
import ApiDashBoardSearch from "@/components/ApiDashboard/ApiDashBoardSearch.vue";

import HistoryActions from "@/components/Payroll/View/BonusAndDed/HistoryActions.vue";
import HistoryTable from "@/components/Payroll/View/BonusAndDed/HistoryTable.vue";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";

import { useRoute } from "vue-router";

import { ref, computed, reactive, onMounted, watch } from "vue";
import { helperFunctions } from "@/composable/helperFunctions";
const store = useDataStore();

const { query } = store;

const { getMostRecentBonusOrDeduction } = storeToRefs(store);
const route = useRoute();
const pageType = route.query.type;
// console.log("route is", route.query.type);
const bonusOrDedDataHistory = computed(
  () =>
    getMostRecentBonusOrDeduction.value && getMostRecentBonusOrDeduction.value
);
const handlePagesize = (e) => {
  console.log("top", e);
  pageSize.value = e;
  apiFilter.paging.limit = e;
};
const updatePage = (page) => {
  console.log("page", page);
  currentPage.value = page;
  apiFilter.paging.skip = page === 1 ? 0 : pageSize.value * (page - 1);
};
const pageSize = ref(5);
const currentPage = ref(1);
const totalRecords = ref(10);

const loading = ref(false);
const handleMonthSelect = (e) => {
  console.log("month", e);
  apiFilter.month = getMonthNumber(e);
};
function getMonthNumber(month) {
  // Convert month to lowercase to handle case-insensitive input
  month = month.toLowerCase();

  switch (month) {
    case "january":
      return 0;
    case "february":
      return 1;
    case "march":
      return 2;
    case "april":
      return 3;
    case "may":
      return 4;
    case "june":
      return 5;
    case "july":
      return 6;
    case "august":
      return 7;
    case "september":
      return 8;
    case "october":
      return 9;
    case "november":
      return 10;
    case "december":
      return 11;
    default:
      return null; // Invalid input
  }
}
const apiFilter = reactive({
  type: route.query.type,

  paging: {
    limit: pageSize.value,
    skip: 0,
    search: null,
  },
  sort: {
    employeeName: null,
    amount: null,
  },
  bndName: null,
  month: null,
  year: null,
});

watch(
  apiFilter,
  () => {
    getBonusOrDed();
  },
  { deep: true }
);
function printBase64(base64String) {
  // Create a new image element
  const img = new Image();
  img.src = base64String;

  // When the image loads, initiate printing
  img.onload = function() {
    // Create a new window for printing
    const printWindow = window.open('', '_blank');
    printWindow.document.write('<html><head><title>Print</title></head><body></body></html>');
    
    // Append the image to the body of the print window
    printWindow.document.body.appendChild(img);
    
    // Print the window
    printWindow.print();
  };
}
const handleAction = async (e) => {
  const payload = {
    input: apiFilter,
  };
  try {
    loading.value = true;

    const res = await query({
      endpoint: "DownloadBonusAndDeductionHistory",
      payload,
      service: "PAYROLL",
    });
    if (res.success) {
      const base64 = res.data.base64;
      const fileName = res.data.fileName;

      if (e === "Download") {
        downloadBase64(base64, fileName);
      } else {
        printPDF(base64);
        // printBase64(base64)
      // downloadBase64(base64, fileName);

      }
    }
  } catch (err) {
    console.log(err);
  } finally {
    loading.value = false;
  }
};
const getBonusOrDed = async () => {
  const payload = {
    input: {...apiFilter,year:apiFilter.year.toString(),month:apiFilter.month.toString()},
  };
  try {
    loading.value = true;

    const res = await query({
      endpoint: "GetBonusAndDeductionHistory",
      payload,
      service: "PAYROLL",
      storeKey: "bonusOrDeductionHistory",
    });
    if (res.success) {
      totalRecords.value = res.data.paginationData.totalRecords;
      console.log("After query", res.data);
    }
  } catch (err) {
    console.log(err);
  } finally {
    loading.value = false;
  }
};
const { formatAmount, downloadBase64,printPDF } = helperFunctions;
const bonusDeduction = computed(() => store.getBonusAndDeduction);
const options = computed(() => bonusDeduction.value.map((each) => each.name));

const selectedYear = ref(null);
function searchData(e) {
  apiFilter.paging.search = e.toLowerCase();
  console.log(e);
}
const selectedMonth = ref(null);
const yearsArr = computed(() => {
  const currentYear = new Date().getFullYear();
  const lastTenYears = [];
  for (let i = 0; i < 10; i++) {
    lastTenYears.push(currentYear - i);
  }
  return lastTenYears;
});
const selectedOption = ref(null);
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
onMounted(async () => {
  await getBonusOrDed();
  // visibleData.value = employees;
});
</script>

<style lang="scss" scoped></style>
