<template>
  <h4
    class="text-center font-semibold absolute top-0 left-0 w-full bg-white pt-6 pb-4"
  >
    {{ title }}
  </h4>
  <div class="grid grid-cols-1 gap-5 p-4 pb-8">
    <div
      class="bg-newLightGreen text-center p-4 px-6 grid grid-cols-1 gap-3 rounded-3xl mt-16"
    >
      <h4 class="text-4xl">😔</h4>
      <h3 class="text-xl font-bold text-dark-900">Aww, sorry!</h3>
      <p class="text-dark-800 text-center">
        You are not currently eligible to take this loan. See eligibility below.
      </p>
    </div>

    <div class="grid grid-cols-1 gap-5 mt-2">
      <h2 class="text-xl font-bold">Eligibility Criteria:</h2>

      <div
        v-for="(item, i) in criteries"
        :key="`criteria-${i}`"
        style="box-shadow: 0px 9px 18px 0px #aaaaaa26"
        class="grid grid-cols-1 gap-2 rounded-2xl bg-white p-2.5 border border-background"
      >
        <div class="flex items-center gap-4">
          <div
            style="background-color: #707170"
            class="flex h-9 w-9 items-center justify-center rounded-lg text-white"
          >
            {{ i + 1 }}
          </div>
          <span class="text-lg font-semibold">{{ item.title }}</span>
        </div>

        <p class="text-sm leading-6" v-html="item.description" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();

const loanType = computed(() => route.query.type || "Title");

const title = computed(() => {
  return loanType.value.split("_").join(" ");
});

const criteries = computed(() => {
  const arr = [
    {
      title: "Registered Business",
      description: `Must be a registered business`,
      show: true,
    },
    {
      title: "KYC Tier 4",
      description: `Your must be on at least tier 4 in KYC `,
      show: true,
    },
    {
      title: "Subscription plan",
      description: `Must be on either <strong>EaziPlus</strong>, <strong>EaziBusiness</strong> or <strong>EaziPremium</strong> plan`,
      show: true,
    },
    {
      title: "At least 3 months payroll run",
      description: `Must have run payroll for at least 3 months`,
      show: loanType.value == "Small_Business_Loan",
    },
  ];

  return arr.filter((el) => el.show);
});
</script>

<style></style>
